import { Component, OnInit } from '@angular/core';
import { RegoService } from 'src/app/rego.service';
import { Router, ActivatedRoute } from '@angular/router';
import settingsData from '../../assets/settings.json';
declare var geoip2: any;
declare var swal: any;
declare var encryptAndRedirectToMID: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  buttonText = 'Create account';
  // recaptchaKey = RESOURCES.RECAPTCHAKEY;
  recaptchaKey = '';

  listSelected = null;

  lists = {
    countries: {
      label: 'Country of Residence'
    },
    professions: {
      label: 'Profession'
    },
    specialties: {
      label: 'Specialty'
    },
    conditions: {
      label: 'Condition'
    }
  };

  refdata = {
    countries: [],
    professions: [],
    specialties: [],
    conditions: []
  };

  errorMessage = '';
  emailFormat = new RegExp(/^[^@]+@[^.]+\..*/);
  regex = new RegExp(/^[a-zA-Z]{3}\d{10}/);
  confirmPassword = '';
  registrationForm = {
    version: 5,
    title: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    countryCode: null,
    profession: null,
    otherProfession: '',
    otherSpecialty: '',
    specialty: null,
    condition: null,
    recaptchaResponse: null,
    contactDetails: {
      company: '',
      postCode: '',
      contactCategory: '',
      contactProfession: '',
      contactSpecialty: '',
      ownedBy: '',
      notes: '',
      countryCode: '',
      state: '',
      wallConsent1: '',
      wallConsent2: ''
    }
  };
  loading = false;
  interactPrivacy = false;
  abbottAgree = false;
  abbottPrivacy = false;
  formSelection = '';
  countries = [
    {
      id: 'AU',
      name: 'Australia',
      states: [
        {
          id: 'AU-ACT',
          name: 'Australian Capital Territory'
        },
        {
          id: 'AU-NSW',
          name: 'New South Wales'
        },
        {
          id: 'AU-NT',
          name: 'Northern Territory'
        },
        {
          id: 'AU-QLD',
          name: 'Queensland'
        },
        {
          id: 'AU-SA',
          name: 'South Australia'
        },
        {
          id: 'AU-TAS',
          name: 'Tasmania'
        },
        {
          id: 'AU-VIC',
          name: 'Victoria'
        },
        {
          id: 'AU-WA',
          name: 'Western Australia'
        }
      ]
    },
    {
      id: 'NZ',
      name: 'New Zealand',
      states: [
        {
          id: 'NZ-AUK',
          name: 'Auckland'
        },
        {
          id: 'NZ-BOP',
          name: 'Bay of Plenty'
        },
        {
          id: 'NZ-CAN',
          name: 'Canterbury'
        },
        {
          id: 'NZ-CIT',
          name: 'Chatham Islands Territory'
        },
        {
          id: 'NZ-GIS',
          name: 'Gisborne'
        },
        {
          id: 'NZ-HKB',
          name: 'Hawke’s Bay'
        },
        {
          id: 'NZ-MWT',
          name: 'Manawatu-Wanganui'
        },
        {
          id: 'NZ-MBH',
          name: 'Marlborough'
        },
        {
          id: 'NZ-NSN',
          name: 'Nelson'
        },
        {
          id: 'NZ-NTL',
          name: 'Northland'
        },
        {
          id: 'NZ-OTA',
          name: 'Otago'
        },
        {
          id: 'NZ-STL',
          name: 'Southland'
        },
        {
          id: 'NZ-TKI',
          name: 'Taranaki'
        },
        {
          id: 'NZ-TAS',
          name: 'Tasman'
        },
        {
          id: 'NZ-WKO',
          name: 'Waikato'
        },
        {
          id: 'NZ-WGN',
          name: 'Wellington'
        },
        {
          id: 'NZ-WTC',
          name: 'West Coast'
        }
      ]
    }
  ]

  constructor(private regoService: RegoService) {
    if (window.location.hostname === 'localhost') {
      this.recaptchaKey = '6Lc3hxIUAAAAAC_-d8oueRR9jtUUugcltkbDgZE2'; // localhost
    } else {
      this.recaptchaKey = '6LfV2S0UAAAAAByxHbcvS8D2F-ErWZsunv5Vmqhe';
    }
    // geoip2.country((response) => {
    //   console.log('GEOIP', response);
    //   this.registrationForm.countryCode = response.country.iso_code;
    //   console.log(this.registrationForm);
    // }, (err) => {
    //   console.log('GEOIP', err);
    // }, {});

    // Promise.all([regoService.getCountries(), regoService.getProfessions(), regoService.getConditions()]).then((results) => {
    //   this.refdata.countries = results[0];
    //   this.refdata.professions = results[1];
    //   this.refdata.conditions = results[2];
    //   this.loading = false;
    //   console.log(this.registrationForm);
    // }).catch((errors) => {
    //   console.log(errors);
    // });
  }

  ngOnInit() {
    console.error(this.referrer);
  }

  getStates() {
    let states = [];
    for (let i = 0; i < this.countries.length; i++) {
      if (this.countries[i].id === this.registrationForm.contactDetails.countryCode) {
        states = this.countries[i].states;
      }
    }
    return states;
  }

  resetForm() {
    this.interactPrivacy = false;
    this.abbottAgree = false;
    this.abbottPrivacy = false;
    this.confirmPassword = '';
    this.registrationForm = {
      version: 5,
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      countryCode: null,
      profession: null,
      otherProfession: '',
      otherSpecialty: '',
      specialty: null,
      condition: null,
      recaptchaResponse: null,
      contactDetails: {
        company: '',
        postCode: '',
        contactCategory: '',
        contactProfession: '',
        contactSpecialty: '',
        ownedBy: '',
        notes: '',
        countryCode: '',
        state: '',
        wallConsent1: '',
        wallConsent2: ''
      }
    };
  }

  resolved(captchaResponse: string) {
    this.registrationForm.recaptchaResponse = captchaResponse;
  }

  submit() {
    if (this.formValid()) {
      this.loading = true;
      this.registrationForm.contactDetails.contactSpecialty = 'Endocrinology';
      if (this.registrationForm.contactDetails.contactProfession === 'General Practitioner') {
        this.registrationForm.profession = 1;
        this.registrationForm.specialty = 11;
        this.registrationForm.contactDetails.contactSpecialty = 'General Practice';
        // @here
      } else if (this.registrationForm.contactDetails.contactProfession === 'Endocrinologist') {
        this.registrationForm.profession = 1;
        this.registrationForm.specialty = 8;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Credentialed Diabetes Educator') {
        this.registrationForm.profession = 5;
        this.registrationForm.specialty = 5008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Diabetes Educator') {
        this.registrationForm.profession = 5;
        this.registrationForm.specialty = 5008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Dietitian') {
        this.registrationForm.profession = 5;
        this.registrationForm.specialty = 5008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Pharmacist') {
        this.registrationForm.profession = 3;
        this.registrationForm.specialty = 3008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Practice Nurse') {
        this.registrationForm.profession = 2;
        this.registrationForm.specialty = 2008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Nurse Practitioner') {
        this.registrationForm.profession = 2;
        this.registrationForm.specialty = 2008;
      } else if (this.registrationForm.contactDetails.contactProfession === 'Other') {
        this.registrationForm.profession = 5;
        this.registrationForm.specialty = 5008;
      }

      if (this.abbottAgree) {
        this.registrationForm.contactDetails.wallConsent1 = 'Agree to user personal information to contact';
      }
      if (this.abbottPrivacy) {
        this.registrationForm.contactDetails.wallConsent2 = 'Agree to user personal information for other programs';
      }
      this.registrationForm.countryCode = this.registrationForm.contactDetails.countryCode;

      console.log(this.registrationForm);

      this.regoService.register(this.registrationForm, this.referrer).then(result => {
        console.log(result);
        this.loading = false;
        grecaptcha.reset();
        this.responseHandling(result);
        this.resetForm();
      }).catch(err => {
        console.log(err);
        this.loading = false;
        alert('Error');
      });
    }
  }


  get referrer () {
    if (! window.location.hash.includes('?')) {
      return null
    }

    const segments = window.location.hash.split('?')

    const search = segments[1]

    const params = search.split('&').map(param => param.split('='))

    const entry = params.find(param => param[0] === 'ref')

    if (! entry) {
      return null
    }

    const [key, value] = entry

    return value
  }

  formValid() {
    if (this.formSelection === 'new') {
      if (this.registrationForm.title === '') {
        swal({title: 'Form is invalid', text: 'Title is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.firstName === '') {
        swal({title: 'Form is invalid', text: 'First Name is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.lastName === '') {
        swal({title: 'Form is invalid', text: 'Last Name is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.emailAddress === '') {
        swal({title: 'Form is invalid', text: 'Email is a required field.', html: true, type: 'error'});
        return false;
      }
      if (!this.emailFormat.test(this.registrationForm.emailAddress)) {
        swal({title: 'Form is invalid', text: 'Please enter a valid email address.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.company === '') {
        swal({title: 'Form is invalid', text: 'Organisation/ Hospital/Clinic Name is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.countryCode === '') {
        swal({title: 'Form is invalid', text: 'Country must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.state === null) {
        swal({title: 'Form is invalid', text: 'State / Region must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.contactProfession === '') {
        swal({title: 'Form is invalid', text: 'Profession must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.password === '') {
        swal({title: 'Form is invalid', text: 'Password is a required field.', html: true, type: 'error'});
        return false;
      } else if (this.registrationForm.password.length < 8) {
        swal({title: 'Form is invalid', text: 'Password must be at least 8 characters.', html: true, type: 'error'});
        return false;
      }
      if (this.confirmPassword === '') {
        swal({title: 'Form is invalid', text: 'Confirm Password is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.password !== this.confirmPassword) {
        swal({title: 'Form is invalid', text: 'The password and confirmation password do not match.', html: true, type: 'error'});
        return false;
      }
      if (!this.validatePassword(this.registrationForm.password)) {
        swal({title: 'Form is invalid', text: 'Password must be at least 8 characters and must include 1 uppercase letter, 1 lowercase letter, and 1 number.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.recaptchaResponse === null) {
        swal({title: 'Form is invalid', text: 'Recaptcha is required.', html: true, type: 'error'});
        return false;
      }
      if (!this.interactPrivacy) {
        swal({title: 'Form is invalid', text: 'You must agree to the INTERACT Terms & Conditions and Privacy Policy.', html: true, type: 'error'});
        return false;
      }
      return true;
    } else if (this.formSelection === 'existing') {
      if (this.registrationForm.firstName === '') {
        swal({title: 'Form is invalid', text: 'First Name is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.lastName === '') {
        swal({title: 'Form is invalid', text: 'Last Name is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.emailAddress === '') {
        swal({title: 'Form is invalid', text: 'myINTERACT Email is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.password === '') {
        swal({title: 'Form is invalid', text: 'myINTERACT Password is a required field.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.countryCode === '') {
        swal({title: 'Form is invalid', text: 'Country must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.state === null) {
        swal({title: 'Form is invalid', text: 'State / Region must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.contactDetails.contactProfession === '') {
        swal({title: 'Form is invalid', text: 'Profession must be selected.', html: true, type: 'error'});
        return false;
      }
      if (this.registrationForm.recaptchaResponse === null) {
        swal({title: 'Form is invalid', text: 'Recaptcha is required.', html: true, type: 'error'});
        return false;
      }
      return true;
    }
  }

  validatePassword(password) {
    return /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password) &&
          password.length >= 8;
  }

  responseHandling(response) {
    if (response.status.code === 0) {
      const alertTitle = 'Registration is successful';
      this.resetForm();
      const msg = 'You will now be redirected';
      swal({title: alertTitle, text: msg, html: true, type: 'success'});
      encryptAndRedirectToMID(this.registrationForm.emailAddress, this.registrationForm.password, settingsData.companyId);
    } else if (response.status.code === 1002) {
      const alertTitle = 'An error has occurred';
      let message = '';
      if (response.data.errorMsg !== undefined) {
        message = response.data.errorMsg;
      } else {
        message = response.data;
      }
      if (response.data.errorCode === 1002) {
        const msg = 'Error: ' + message;
        swal({title: alertTitle, text: msg, html: true, type: 'error'});
        if (response.data.errorCode === 1050) {
          const alertTitle = 'Registration is successful';
          const msg = 'You will now be redirected';
          swal({title: alertTitle, text: msg, html: true, type: 'error'});
          encryptAndRedirectToMID(this.registrationForm.emailAddress, this.registrationForm.password, settingsData.companyId);
        } else {
          const msg = 'Error: ' + response.data.errorMsg;
          swal({title: alertTitle, text: msg, html: true, type: 'error'});
        }
      } else if (response.data.errorCode === 1010) {
        const msg = 'Error: ' + message;
        swal({title: alertTitle, text: msg, html: true, type: 'error'});
      } else if (response.data.errorCode === 1033) {
        const msg = 'Error: ' + message;
        swal({title: alertTitle, text: msg, html: true, type: 'error'});
      } else {
        const msg = 'Error: ' + message;
        swal({title: alertTitle, text: msg, html: true, type: 'error'});
      }
    }
  }
}
