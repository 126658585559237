<div class="main-container">
  <div class="header text-center">
    <div class="container">
      <div class="row">
        <div class="col-6" style="text-align: left;">
            <img class="freestyle-logo" src="./assets/images/fsl-logo.png">
        </div>
        <div class="col-6" style="text-align: right;">
            <img class="abbott-logo" src="./assets/images/abbott.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <span class="back-button noselect" (click)="formSelection = ''" [hidden]="formSelection === ''"><i class="far fa-long-arrow-left"></i> Back</span>
    <div class="spinner-overlay" [hidden]="!loading">
      <div class="loading-spinner-circle"></div>
    </div>
  </div>
  <div class="container" [hidden]="formSelection !== ''">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-selection" style="min-height: 290px;">
          <div class="info-container">
            <div class="row">
              <div class="col-lg-6" style="max-width: calc(100% - 150px);">
                <h1><span style="color: #292883">HEALTHCARE PROFESSIONALS</span> <br><span style="color: #F16639">DIGITAL RESOURCES</span></h1>
                <p>On-demand platform for the latest release information on FreeStyle Libre and continuous support.</p>
              </div>
              <div class="col-lg-6">
              </div>
            </div>
          </div>
          <img class="banner-img" src="./assets/images/banner.png"/>
          <img class="logo-mi" src="./assets/images/logo-mi.svg"/>
        </div>

        <div class="yellow-box">
          <p>Healthcare professionals have immediate access to digital resources, ability to share with patients and available via web or mobile</p>
        </div>

        <div class="row" style="margin-top: 25px;">
          <div class="col-lg-6">
            <h3 class="resource-heading">HCP RESOURCES</h3>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              e- Learning Modules
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Patient Support Materials
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Video & Multimedia
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Webinars / Special Events
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Educational Resources
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Clinical Evidence & Papers
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Patient Start Program
            </div>
          </div>
          <div class="col-lg-6">
            <h3 class="resource-heading">AVAILABLE ON MOBILE</h3>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Sign in once to stay connected
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Android and iOS compatible
            </div>
            <div class="resource-item">
              <img src="./assets/images/icon-plus.svg"/>
              Online and offline access
            </div>

            <div style="margin-bottom: 20px;">
              <img src="./assets/images/myINTERACT_appicon.png" style="width: 36px; margin: 10px 0;"/><br>
              <img src="./assets/images/apple.svg" style="margin-right: 6px;"/>
              <img src="./assets/images/googleplay.svg"/>
            </div>

          </div>
        </div>

      </div>
      <div class="col-lg-6">
        <h1>REGISTER NOW</h1>
        <div class="form-selection">
          <div class="row">
            <div class="col-12">
              <h2>New to myINTERACT?</h2>
              <p>No account yet? Register today</p>
              <button class="new-button" (click)="formSelection = 'new'">Register</button>
            </div>
          </div>
        </div>
        <div class="form-selection">
          <div class="row">
            <div class="col-12">
              <h2>Existing myINTERACT user?</h2>
              <p>Already have an account?<br> Sign in now to connect to FreeStyle Libre.</p>
              <button class="existing-button" (click)="formSelection = 'existing'">Sign in</button>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top: 60px;">
          <div class="col-6" style="text-align: left;">
            <img class="freestyle-logo" src="./assets/images/fsl-logo.png">
          </div>
          <div class="col-6" style="text-align: right;">
            <img class="abbott-logo" src="./assets/images/abbott.svg" style="width: 90px;">
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="form-container" *ngIf="formSelection === 'new'">
    <div class="container">
      <form>
        <div>
          <h2>New to myINTERACT?</h2>
          <p>Please enter your details below and click ‘Register’.</p>
        </div>

        <div class="form-item">
          <label class="marg-top-small" for="Title">Title*</label>
          <select [(ngModel)]="registrationForm.title" id="Title" name="Title" class="form-control">
              <option value="" selected="" disabled=""></option>
              <option value="7">Dr</option>
              <option value="8">Associate Prof</option>
              <option value="9">Prof</option>
              <option value="1">Mr</option>
              <option value="2">Mrs</option>
              <option value="5">Ms</option>
              <option value="4">Miss</option>
              <option value="6">Master</option>
          </select>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="FirstName" class="marg-top-small">First name*</label>
              <input [(ngModel)]="registrationForm.firstName" type="text" id="FirstName" name="FirstName" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="LastName" class="marg-top-small">Last name*</label>
              <input [(ngModel)]="registrationForm.lastName" type="text" id="LastName" name="LastName" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Email" class="marg-top-small">Email*</label>
              <input [(ngModel)]="registrationForm.emailAddress" type="Email" id="emailAddress" name="emailAddress" maxlength="254" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Company" class="marg-top-small">Organisation/ Hospital/Clinic Name*</label>
              <input [(ngModel)]="registrationForm.contactDetails.company" type="text" id="Company" name="Company" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Country" class="marg-top-small">Country*</label>
              <select [(ngModel)]="registrationForm.contactDetails.countryCode" name="Country" id="Country" class="form-control full-width">
                <option disabled="" selected="" value=""></option>
                <option *ngFor="let item of countries" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="State" class="marg-top-small">State / Region*</label>
              <select [(ngModel)]="registrationForm.contactDetails.state" name="State" id="State" class="form-control">
                <option disabled="" selected="" value=""></option>
                <option *ngFor="let item of getStates()" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Profession" class="marg-top-small">Profession*</label>
              <select [(ngModel)]="registrationForm.contactDetails.contactProfession" name="Profession" id="Profession" class="form-control full-width">
                <option disabled="" selected="" value=""></option>
                <option value="Endocrinologist">Endocrinologist</option>
                <option value="Credentialed Diabetes Educator">Credentialed Diabetes Educator</option>
                <option value="Diabetes Educator">Diabetes Educator</option>
                <option value="Dietitian">Dietitian</option>
                <option value="General Practitioner">General Practitioner</option>
                <option value="Practice Nurse">Practice Nurse</option>
                <option value="Nurse Practitioner">Nurse Practitioner</option>
                <option value="Pharmacist">Pharmacist</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="form-item" [hidden]="registrationForm.contactDetails.contactProfession !== 'Other'">
              <label for="OtherProfession" class="marg-top-small">Other</label>
              <input [(ngModel)]="registrationForm.otherProfession" type="text" id="OtherProfession" name="OtherProfession" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="HearAbout" class="marg-top-small">How did you hear about us</label>
              <select [(ngModel)]="registrationForm.contactDetails.notes" name="HearAbout" id="HearAbout" class="form-control full-width">
                <option value="" selected=""></option>
                <option value="Sales Rep">Sales Rep</option>
                <option value="Conference">Conference</option>
                <option value="Advertising">Advertising</option>
                <option value="E-mail">E-mail</option>
                <option value="Webinar">Webinar</option>
                <option value="Colleague">Colleague</option>
              </select>
            </div>
          </div>
        </div>
        <br>

        <div class="row" style="margin-top: 35px;">
            <div class="col-sm-2 col-lg-1">
                <img class="mi-logo" src="assets/images/myINTERACT_appicon.png">
            </div>
            <div class="col-sm-10 col-lg-11">
                <p style="margin-left: 11px;">
                    The registration process will automatically create an account on
                    myINTERACT which requires a password.<br> Please enter a password below.<br>
                    (Password needs to be 8 characters long, including 1 number, 1 lowercase
                    letter and 1 capital letter). <br>
                </p>
            </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <label for="Password" class="marg-top-small">Create Password*:</label>
            <input [(ngModel)]="registrationForm.password" type="password" id="Password" maxlength="30" name="Password" class="form-control full-width">

            <label for="ConfirmPassword" class="marg-top-small">Confirm password*:</label>
            <input [(ngModel)]="confirmPassword" type="password" id="ConfirmPassword" maxlength="30" name="ConfirmPassword" class="form-control full-width"><br>
          </div>
          <div class="col-sm-6">
            <div class="form-group center-block top-buffer">
              <label for="" class="marg-top-small">Verification</label><br><br>


              <re-captcha (resolved)="resolved($event)" siteKey="{{recaptchaKey}}"></re-captcha>
              <p class="error help-block small"></p>
            </div>
          </div>
        </div>

        <div class="row check-box-margin">
          <div class="col-sm-12">
            <div class="checkbox-container">
              <input type="checkbox" id="interact-privacy" class="bbcheckbox" name="interact-privacy" [(ngModel)]="interactPrivacy">
              <label for="interact-privacy" class="checktext2">
                I agree to INTERACT's <a href="https://www.interact.technology/eula" target="_blank">
                Terms & Conditions</a> and <a href="https://s3-us-west-1.amazonaws.com/interact.technology/legal/internet-services/au/privacy.html"
                  target="_blank">Privacy Policy</a>*
                </label>
            </div>
            <div class="checkbox-container">
                <input type="checkbox" id="checkBox2" class="bbcheckbox" name="checkBox2" value="I agree" [(ngModel)]="abbottAgree">
                <label for="checkBox2" class="checktext2">
                  I agree that Abbott Australasia Pty. Ltd. and its representatives may use my personal information: to contact me now or in the future regarding Abbott products and services, and for marketing or promotional purposes. My contact details provided for this purpose are: Email address
                </label>
            </div>
            <div class="checkbox-container">
              <input type="checkbox" id="ownedBy" class="bbcheckbox" name="ownedBy" [(ngModel)]="abbottPrivacy" value="Abbott may use any personal information">
              <label for="ownedBy" class="checktext2">
                Abbott may use any personal information belonging to me, acquired or received by any of its representatives under the Program, in accordance with my consent herein and any use shall be in accordance with Abbott’s privacy policy located at <a href="https://www.freestylelibre.com.au/privacy_policy" target="_blank">https://www.freestylelibre.com.au/privacy_policy</a>
              </label>
            </div>

          </div>
        </div>

        <div class="row text-center" style="margin: 40px 0;">
          <div class="col-12">
            <button name="submit" class="btn btn-lg submit-button" (click)="submit()">Register</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="form-container" *ngIf="formSelection === 'existing'">
    <div class="container">
      <form>
        <div>
          <h2>Existing myINTERACT user?</h2>
          <p>Login in now and accept T&C’s to access Freestyle Libre.</p>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="FirstName" class="marg-top-small">First name*</label>
              <input [(ngModel)]="registrationForm.firstName" type="text" id="FirstName" name="FirstName" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="LastName" class="marg-top-small">Last name*</label>
              <input [(ngModel)]="registrationForm.lastName" type="text" id="LastName" name="LastName" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Email" class="marg-top-small">myINTERACT Email*</label>
              <input [(ngModel)]="registrationForm.emailAddress" type="Email" id="emailAddress" name="emailAddress" maxlength="254" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Password" class="marg-top-small">myINTERACT Password*</label>
              <input [(ngModel)]="registrationForm.password" type="password" id="Password" maxlength="30" name="Password" class="form-control full-width"><br>
              Forgot password? <a href="https://my.interact.technology/interact/#/resetPassword" target="_blank">Click here</a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Country" class="marg-top-small">Country*</label>
              <select [(ngModel)]="registrationForm.contactDetails.countryCode" name="Country" id="Country" class="form-control full-width">
                <option disabled="" selected="" value=""></option>
                <option *ngFor="let item of countries" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-item">
              <label for="State" class="marg-top-small">State / Region*</label>
              <select [(ngModel)]="registrationForm.contactDetails.state" name="State" id="State" class="form-control">
                <option disabled="" selected="" value=""></option>
                <option *ngFor="let item of getStates()" [value]="item.id">{{item.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-item">
              <label for="Profession" class="marg-top-small">Profession*</label>
              <select [(ngModel)]="registrationForm.contactDetails.contactProfession" name="Profession" id="Profession" class="form-control full-width">
                <option disabled="" selected="" value=""></option>
                <option value="Endocrinologist">Endocrinologist</option>
                <option value="Credentialed Diabetes Educator">Credentialed Diabetes Educator</option>
                <option value="Diabetes Educator">Diabetes Educator</option>
                <option value="Dietitian">Dietitian</option>
                <option value="General Practitioner">General Practitioner</option>
                <option value="Practice Nurse">Practice Nurse</option>
                <option value="Nurse Practitioner">Nurse Practitioner</option>
                <option value="Pharmacist">Pharmacist</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="form-item" [hidden]="registrationForm.contactDetails.contactProfession !== 'Other'">
              <label for="OtherProfession" class="marg-top-small">Other</label>
              <input [(ngModel)]="registrationForm.otherProfession" type="text" id="OtherProfession" name="OtherProfession" maxlength="50" class="form-control full-width" value="">
            </div>
          </div>
          <div class="col-sm-6">

          </div>
        </div>
        <br>
        <div class="row check-box-margin">
          <div class="col-sm-12">
            <div class="checkbox-container">
                <input type="checkbox" id="checkBox2" class="bbcheckbox" name="checkBox2" value="I agree" [(ngModel)]="abbottAgree">
                <label for="checkBox2" class="checktext2">
                  I agree that Abbott Australasia Pty. Ltd. and its representatives may use my personal information: to contact me now or in the future regarding Abbott products and services, and for marketing or promotional purposes. My contact details provided for this purpose are: Email address
                </label>
            </div>
            <div class="checkbox-container">
              <input type="checkbox" id="ownedBy" class="bbcheckbox" name="ownedBy" [(ngModel)]="abbottPrivacy" value="Abbott may use any personal information">
              <label for="ownedBy" class="checktext2">
                Abbott may use any personal information belonging to me, acquired or received by any of its representatives under the Program, in accordance with my consent herein and any use shall be in accordance with Abbott’s privacy policy located at <a href="https://www.freestylelibre.com.au/privacy_policy" target="_blank">https://www.freestylelibre.com.au/privacy_policy</a>
              </label>
            </div>

          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group center-block top-buffer">
              <label for="" class="marg-top-small">Verification</label><br><br>
              <re-captcha (resolved)="resolved($event)" siteKey="{{recaptchaKey}}"></re-captcha>
              <p class="error help-block small"></p>
            </div>
          </div>
          <div class="col-sm-6"></div>
        </div>

        <div class="row text-center" style="margin: 40px 0;">
          <div class="col-12">
            <button name="submit" class="btn btn-lg submit-button" (click)="submit()">Register</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</div>
<div class="footer container">
  Apple and the Apple logo are trademarks of Apple Inc, registered in the U.S. and other countries. Google Play logo are trademarks of Google LLC.
The sensor housing, FreeStyle, Libre, and related brand marks are marks of Abbott. Information contained herein is for distribution outside of the USA only. Abbott Australasia Pty. Ltd., Abbott Diabetes Care, 666 Doncaster Road, Doncaster, Victoria 3108, Australia. ABN 95 000 180 389 ADC-97522 V1.0
</div>
