import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import settingsData from '../assets/settings.json';

@Injectable({
  providedIn: 'root'
})
export class RegoService {

  constructor(private http: HttpClient) { }

  // getCountries() {
  //   return new Promise((resolve, reject) => {
  //     request('GET', RESOURCES.DOMAIN + '/rest/refdata/countries', false).then(function(response){
  //       console.log(response);
  //       resolve(response);
  //     }).catch(function(error){
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }

  // getProfessions() {
  //   return new Promise((resolve, reject) => {
  //     request('GET', RESOURCES.DOMAIN + '/rest/refdata/professions', true).then(function(response){
  //       console.log(response);
  //       resolve(response);
  //     }).catch(function(error){
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }

  // getSpecialties(professionId) {
  //   return new Promise((resolve, reject) => {
  //     var params = ''
  //     if (professionId != null) {
  //       params = '?professionId=' + professionId;
  //     }
  //     request('GET', RESOURCES.DOMAIN + '/rest/refdata/specialties' + params, true).then(function(response){
  //       console.log(response);
  //       resolve(response);
  //     }).catch(function(error){
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }

  // getConditions() {
  //   return new Promise((resolve, reject) => {
  //     request('GET', RESOURCES.DOMAIN + '/rest/refdata/conditions', true).then((response) => {
  //       console.log(response);
  //       resolve(response);
  //     }).catch(function(error){
  //       console.log(error);
  //       reject(error);
  //     });
  //   });
  // }

  register(data, ref = null) {
    // const accessCode = settingsData.accessCode;
    const registrationObj = data;

    let accessCodes = []
    if (data.countryCode == 'AU') {
      if (data.contactDetails.contactProfession === 'Pharmacist') {
        accessCodes = ['freestylepharmacy'];
      } else if (data.contactDetails.contactProfession === 'General Practitioner' || data.contactDetails.contactProfession === 'Practice Nurse') {
        accessCodes = ['FREESTYLEGP'];
      } else {
        accessCodes = ['freestylelibre'];
      }
    } else if (data.countryCode == 'NZ') {
        accessCodes = ['FSLNZ'];
    }

    if (ref === 'thinkgp') {
      accessCodes.push('THINKGP');
    }

    registrationObj.qrAccessCodes = accessCodes;

    if (window.location.hostname === 'localhost') {
      registrationObj._env = 'localhost';
    }
    console.log(registrationObj)
    return new Promise((resolve, reject) => {
      // this.http.post('http://127.0.0.1:3000/customRegister', registrationObj, { }).subscribe((response: any) => {
      //   console.log(response);
      //   resolve(response);
      // });
      let apiUrl = 'https://my.interact.technology/utilities/new/api.php?customRegister';
      // const isTestEnvironment = window.location.hostname === 'test-regos.interact.technology' || 'test-rego.interact.technology' || 'localhost';
      // if (isTestEnvironment) {
      //   apiUrl = 'https://test-my.interact.technology/utilities/new/api.php?customRegister';
      // }
      this.http.post(apiUrl, registrationObj, { }).subscribe((response: any) => {
        console.log(response);
        resolve(response);
      });
    });
      // $http({
      //   method: 'POST',
      //   url: RESOURCES.DOMAIN + '/utilities/new/api.php?register',
      //   // url: 'http://127.0.0.1:3000/register',
      //   headers: {},
      //   data: data
      // }).then( (response) => {
      //   if (response.data.status.code === 1002) {
      //     reject(response.data);
      //   } else {
      //     resolve(response.data); //if response is successful just return Data
      //   }
      // }).catch(err) => {
      //   console.log('requestError:', response);
      //   var error = response.data;
      //   reject(error);
      // });
  }

}
